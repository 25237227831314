import { Grid, InputAdornment, Typography } from '@mui/material';
import EmailIcon from 'assets/png/email.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { CRMSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { useAppSelector } from 'store/hooks';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import { CRMType } from 'types';
import AddIcon from 'assets/iconcomponents/addicon';
import { ClientsENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import Deleteimgicon from 'assets/png/Deleteblack.svg';
import Loader from 'components/loader';
import { toast } from 'react-toastify';

const CRMDETAILS = ({ setformview, formview, setFormfinish }: any) => {
  const [Ld, setLd] = useState(false);
  const navigate = useNavigate();
  const [crmlist, setcrmlist] = useState<any>([]);
  const [removeCrmds, setRemoveCrmids] = useState<any>([]);
  const [searchParams]: any = useSearchParams();
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue,
    // trigger,
    // setError,
    watch,
    formState: { errors },
  } = useForm<CRMType>({
    criteriaMode: 'all',
    defaultValues: {
      items: [
        {
          choosecrm: '',
          title: '',
          firstname: '',
          lastname: '',
          designation: '',
          email: '',
          phoneno: undefined,
          mobileno: undefined,
          countryCodeText: '',
          rowId: '',
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CRMSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  function handleLocalstoragefunction(idy: number) {
    if (!_.isEmpty(crmlist)) {
      const localstate: any = crmlist.find(
        (rd1: any) => rd1.value === watch(`items.${idy}.choosecrm`),
      );
      if (!_.isEmpty(localstate && localstate.data)) {
        setValue(
          `items.${idy}.title`,
          localstate && localstate.data.title
            ? String(localstate.data.title).toLowerCase() === 'mr'
              ? 'mr'
              : 'mrs'
            : '',
        );
        // setValue(`items.${idy}.rowId`, localstate && localstate.data.id ? localstate.data.id : '');
        setValue(
          `items.${idy}.firstname`,
          localstate && localstate.data.firstName ? localstate.data.firstName : '',
        );
        setValue(
          `items.${idy}.lastname`,
          localstate && localstate.data.lastName ? localstate.data.lastName : '',
        );
        setValue(
          `items.${idy}.designation`,
          localstate && localstate.data.designation ? localstate.data.designation : '',
        );
        setValue(
          `items.${idy}.email`,
          localstate && localstate.data.login && localstate.data.login.email
            ? localstate.data.login.email
            : '',
        );
        setValue(
          `items.${idy}.mobileno`,
          localstate && localstate.data.mobileNumber ? localstate.data.mobileNumber : undefined,
        );
        setValue(
          `items.${idy}.countryCodeText`,
          localstate && localstate.data.countryCode ? localstate.data.countryCode : '',
        );

        setValue(
          `items.${idy}.phoneno`,
          localstate && localstate.data.phoneNumber && localstate.data.countryCode
            ? `${localstate.data.countryCode}${localstate.data.phoneNumber}`
            : undefined,
        );
      }
    }
  }

  useEffect(() => {
    setLd(true);
    getAllListData(
      `${SETTING_ENDPOINT.users}/lite?crm=true&status=true&moduleId=2&screenId=4&organizationId=${orgdy}&sortby=name&order=asc`,
      true,
    )
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          setcrmlist(
            res.data.map((li1: any) =>
              li1 && !_.isEmpty(li1) && li1.id
                ? {
                    value: li1.id,
                    label:
                      li1.firstName && li1.lastName
                        ? String(`${li1.firstName} ${li1.lastName}`)
                        : '',
                    data: li1,
                  }
                : '',
            ),
          );
        }
        setLd(!true);
      })
      .catch(() => {
        // console.log(e);
        setLd(!true);
      });
    getInitialStatus();
  }, []);

  function onSubmitForm(data: any) {
    // data.items?.map((re: any) => {
    //   if (re?.rowId) {
    //     re['id'] = re.rowId;
    //     delete re.rowId;
    //   }
    //   return re;
    // });
    const finalData = data.items.map((re: any) => ({
      userId: re.choosecrm,
      ...(re.rowId ? { id: re.rowId } : {}),
    }));
    const formdata: any = new FormData();
    formdata.append('clientTab', 'crmDetails');
    formdata.append('clientId', searchParams.get('clientid') || formview.clientId);
    formdata.append('clientCRM', JSON.stringify(finalData));
    if (removeCrmds.length) {
      formdata.append('removeClientCrmIds', JSON.stringify(removeCrmds));
    }
    // formdata.append('editReason', '');
    setLd(true);
    addNewData(
      formdata,
      `${ClientsENDPOINT.Client}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res: any) => {
        setformview((prev: any) => ({
          ...prev,
          crm: false,
          spoc: false,
          clientdetail: false,
          termsCondition: false,
          businessDetail: true,
          clientId: res && res?.id,
        }));
        setFormfinish((prev: any) => ({
          ...prev,
          crm: true,
          spoc: true,
          clientdetail: true,
          termsCondition: false,
          businessDetail: false,
        }));
        setLd(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(e);
          navigate('/dashboard');
        }
        setLd(false);
        console.log(e);
      });
  }
  const removeSpoc = (index: number, id: string) => {
    if (id) {
      removeCrmds.push(id);
      setRemoveCrmids([...removeCrmds]);
    }
    remove(index);
  };
  const getInitialStatus = async () => {
    if (searchParams.get('clientid') || formview.clientId) {
      setLd(true);
      await getSingleData(searchParams.get('clientid') || formview.clientId, ClientsENDPOINT.Client)
        .then((resp: any) => {
          const Day: any = [];
          const crmData =
            resp && resp.data && resp.data[0]?.clientsCrm.length && resp.data[0]?.clientsCrm;
          crmData.map((re: any) => {
            Day.push({
              choosecrm: re && re.userId ? re.userId : '',
              rowId: re && re.id ? re.id : '',
              firstname: re && re.user.firstName ? re.user.firstName : '',
              lastname: re && re.user.lastName ? re.user.lastName : '',
              designation: re && re.user.designation ? re.user.designation : '',
              email: re && re.user.login && re.user.login.email ? re.user.login.email : '',
              phoneno:
                re && re.user.phoneNumber && re.user.countryCode
                  ? `${re.user.countryCode}${re.user.phoneNumber}`
                  : undefined,
              mobileno: re && re.user.mobileNumber ? re.user.mobileNumber : undefined,
              countryCodeText: re && re.user.countryCode ? re.user.countryCode : '',
            });
          });
          setValue('items', Day);
          setFormfinish((prev: any) => ({
            ...prev,
            crm: resp?.data[0]?.clientsCrm.length ? true : false,
            spoc: resp?.data[0]?.clientsSpoc.length ? true : false,
            clientdetail: resp?.data[0]?.corporateName ? true : false,
            termsCondition: resp?.data[0]?.clientsAgreement.length ? true : false,
            businessDetail: resp?.data[0]?.clientsBusinessInfo.length ? true : false,
          }));
          setLd(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(e);
            navigate('/dashboard');
          }
          setLd(false);
          console.log(e);
        });
    }
  };
  useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <Grid item md={12} sm={8} xs={8}>
      {Ld && <Loader />}
      <Typography component={'div'} className='form-box'>
        <Typography
          component={'div'}
          className='client-form-photo-area'
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography component={'p'} className='side-client-text'>
            CRM Details
          </Typography>
          <AddIcon
            onClick={() =>
              fields.length === crmlist.length
                ? ''
                : append({
                    choosecrm: '',
                    title: '',
                    firstname: '',
                    lastname: '',
                    designation: '',
                    email: '',
                    phoneno: undefined,
                    mobileno: undefined,
                    countryCodeText: '',
                    rowId: '',
                  })
            }
          />
        </Typography>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          {fields.map((ers: any, idx: number) => {
            return (
              <React.Fragment key={ers.id}>
                {idx !== 0 ? (
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      margin: '32px 0px',
                    }}></div>
                ) : (
                  <></>
                )}
                <Grid container columnSpacing={4.25}>
                  {idx !== 0 ? (
                    <div
                      className='list-del-add-icon'
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.rowId`}
                        render={() => {
                          return (
                            <span
                              style={{ cursor: 'pointer', marginRight: '24px' }}
                              onClick={() => {
                                removeSpoc(idx, ers.rowId);
                              }}>
                              <img src={Deleteimgicon} alt='delete' />
                            </span>
                          );
                        }}
                      />

                      <span>
                        <AddIcon
                          onClick={() => {
                            append({
                              choosecrm: '',
                              title: '',
                              firstname: '',
                              lastname: '',
                              designation: '',
                              email: '',
                              phoneno: undefined,
                              mobileno: undefined,
                              countryCodeText: '',
                              rowId: '',
                            });
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>
                        Choose CRM <span>*</span>
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.choosecrm`}
                        render={({ field: { onChange, value, name } }) => {
                          // console.log(value,'value choose crm');
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              options={crmlist}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                handleLocalstoragefunction(idx);
                              }}
                              placeHolder='Select CRM'
                              // isMulti={true}
                              error={errors?.items?.[idx]?.choosecrm?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12} sx={{ opacity: 0 }}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Title</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.title`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              labelKey={'label'}
                              valueKey={'value'}
                              disabled
                              options={[
                                { label: 'Mr.', value: 'mr' },
                                  { label: 'Miss.', value: 'miss' },
                                  { label: 'Mrs.', value: 'mrs' },
                              ]}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              placeHolder='Select Title'
                              // isMulti={true}
                              error={errors?.items?.[idx]?.title?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>First Name</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.firstname`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter First Name'
                              error={errors?.items?.[idx]?.firstname?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Last Name</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.lastname`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            // <CustomSelect
                            //   labelKey={'label'}
                            //   valueKey={'value'}
                            //   options={designationData}
                            //   name={name}
                            //   value={value}
                            //   onChange={(e) => {
                            //     onChange(e);
                            //   }}
                            //   placeHolder='Designation'
                            //   error={errors.designation?.message}
                            // />
                            <CustomInput
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Last Name'
                              error={errors?.items?.[idx]?.lastname?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Designation</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.designation`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Designation'
                              error={errors?.items?.[idx]?.designation?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Email</Typography>

                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.email`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img src={EmailIcon} alt='email' />
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                              value={value}
                              name={name}
                              className='custom-input'
                              placeHolder='Enter Email'
                              error={errors?.items?.[idx]?.email?.message}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>{' '}
                <Grid container columnSpacing={4.25}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Phone No</Typography>
                      <Controller
                        control={control}
                        disabled
                        defaultValue={undefined}
                        name={`items.${idx}.phoneno`}
                        render={({ field: { value } }) => {
                          return (
                            <CustomPhoneInput
                              disabled
                              placeholder=''
                              containerClass={
                                errors?.items?.[idx]?.phoneno?.message ? 'phone-input-error' : ''
                              }
                              inputClass={
                                errors?.items?.[idx]?.phoneno?.message
                                  ? 'phone-input phone-input-no-border'
                                  : 'phone-input'
                              }
                              initialCountry={'in'}
                              specialLabel={''}
                              searchClass={'search-custom-class'}
                              value={value}
                              enableSearch={true}
                              searchNotFound={'No Country Found'}
                              inputStyle={{
                                width: '100%',
                                padding: '9px 11px',
                                borderRadius: '6px',
                                paddingLeft: '60px',
                                color: value && String(value).length > 0 ? '#bdbdbd' : '#bdbdbd',
                                // color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                fontSize: '16px',
                                fontFamily: 'Poppins-Regular',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                // backgroundColor:'#eaecf0'
                              }}
                              error={
                                errors?.items?.[idx]?.phoneno?.message
                                  ? errors?.items?.[idx]?.phoneno?.message
                                  : ''
                              }
                              inputProps={{ tabIndex: 11 }}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  {/* <Grid item md={6} sm={12} xs={12} >
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Mobile No</Typography>
                      <Controller
                        control={control}
                        defaultValue={undefined}
                        name={`items.${idx}.mobileno`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            // <CustomInput
                            //   value={value}
                            //   name={name}
                            //   className='custom-input'
                            //   placeHolder='Phone Number'
                            //   error={errors.phoneNumber?.message}
                            //   onChange={(e) => {
                            //     onChange(e);
                            //   }}
                            // />

                            <CustomPhoneInput
                              placeholder=''
                              containerClass={
                                errors?.items?.[idx]?.mobileno?.message ? 'phone-input-error' : ''
                              }
                              inputClass={
                                errors?.items?.[idx]?.mobileno?.message
                                  ? 'phone-input phone-input-no-border'
                                  : 'phone-input'
                              }
                              initialCountry={'in'}
                              specialLabel={''}
                              searchClass={'search-custom-class'}
                              value={value}
                              enableSearch={true}
                              searchNotFound={'No Country Found'}
                              inputStyle={{
                                width: '100%',
                                padding: '9px 11px',
                                borderRadius: '6px',
                                paddingLeft: '60px',
                                color: value && String(value).length > 0 ? '#bdbdbd' : '#bdbdbd',
                                fontSize: '16px',
                                fontFamily: 'Poppins-Regular',
                                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                              }}
                              // onChange={(value, data, event, formattedValue) => {
                              //   const { countryCode, dialCode } = data;
                              //   // setValue(
                              //   //   'homePhoneISO2',
                              //   //   countryCode.toUpperCase(),
                              //   // );
                              //   // console.log(countryCode,'countryCode',value,data);
                              //   onChange(formattedValue);
                              //   //   setValue('phoneNumber', value);
                              //   //   setValue('countryCode', dialCode);
                              //   //   trigger('phoneNumber');
                              // }}
                              error={
                                errors?.items?.[idx]?.mobileno?.message
                                  ? errors?.items?.[idx]?.mobileno?.message
                                  : ''
                              }
                              inputProps={{ tabIndex: 11 }}
                              disabled
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid> */}
                </Grid>
              </React.Fragment>
            );
          })}
        </form>
      </Typography>
    </Grid>
  );
};

export default CRMDETAILS;
