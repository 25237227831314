/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TableFooter,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import AddIcon from 'assets/png/addplus.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { To, useNavigate } from 'react-router-dom';
import {
  deleteData,
  getAllListData,
  getS3ApiFile,
  getSingleData,
  patchData,
  updateData,
} from 'services/CommonServices';
import { useEffect, useLayoutEffect, useState } from 'react';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import { useDebounce } from 'hooks';
import { FILE_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Paginate from 'components/Pagination/Pagination';
import ImageWithAuth from 'components/ProfileImage';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import _ from 'lodash';
import { AccessDenied } from 'utils/helper';
import ImportFunctionality from '../Common/import';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  sortby?: string | null;
  status?: string | null;
  take?: number | null;
  name?: string | null;
  email?: string | null;
  roleId?: number | null;
}
const Defaultparam = {
  search: null,
  skip: 0,
  order: 'asc',
  sortby: 'name',
  status: null,
  take: 50,
  address: null,
  pincode: null,
  roleId: 15,
};

const ProductAdministrator = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const navigate = useNavigate();
  const [importUpload, setImportUpload] = useState<any>(false);
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [Ld, setLd] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const getList = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.roleId ? `roleId=${debouncedParams.roleId}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    getAllListData(
      `${SETTING_ENDPOINT.users}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setpagetotal(Number(val.total));
          setcommonstate([...val.data]);
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(!true);
      });
  };

  useEffect(() => {
    getList();
  }, [debouncedParams]);

  const deletebtn = async (id: any) => {
    setLd(true);
    try {
      await deleteData(id, `${SETTING_ENDPOINT.users}`, true);
      // toast.success(deleted.message, alertOptions);
      setConfirmOpen({
        show: false,
        id: '',
      });
      getList();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLd(!true);
    }
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  const reInvite = async (id: string) => {
    await patchData(id, {}, `${SETTING_ENDPOINT.users}/invite`, true);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Product Administrator
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>Product Administrator</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <div className='expot-icon'>
                <span>
                  <ExcelIcon
                    url={'users?type=export&roles=15&order=asc&sortby=name'}
                    fileName={'Product_Admin'}
                    setLoader={setLd}
                  />
                </span>
              </div>{' '}
              <Button
                className='add-btn'
                onClick={() => setImportUpload(true)}
                style={{ marginRight: '20px' }}>
                Import
              </Button>
              <Button
                className='add-btn'
                onClick={() => navigate('/settings/product-administrator/add')}>
                <img src={AddIcon} alt='plus' />
                Add Product Administrator
              </Button>
            </Typography>
          </Typography>

          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <Typography component={'div'} className='mt-32'>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Name{' '}
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Role
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('roleName', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('roleName', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Phone
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('phoneNumber', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('phoneNumber', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Status{' '}
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('status', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('status', 'asc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell className='w270'>Resend Password </TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                display: 'flex',
                                gap: '16px',
                                alignItems: 'center',
                              }}>
                              {row && row.imageId ? (
                                <Typography className='ip-img'>
                                  <ImageWithAuth className='default-ippic' id={row.imageId} />
                                </Typography>
                              ) : (
                                <Typography className='ip-img'>
                                  <Typography className='default-iptext'>
                                    {row.firstName.toUpperCase().trim().split('')[0] +
                                      row.lastName.toUpperCase().trim().split('')[0]}
                                  </Typography>
                                </Typography>
                              )}
                              {row.firstName ? `${row.firstName} ${row.lastName}` : '-'}
                            </TableCell>
                            <TableCell>
                              {row && row.roleName
                                ? row.roleId === 15
                                  ? 'Administrator'
                                  : row.roleName
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {row && row.phoneNumber
                                ? `${row.countryCode} ${row.phoneNumber}`
                                : '-'}
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={row && row.status ? 'active-badge' : 'inactive-badge'}>
                                {row.status === true ? 'Active' : 'Inactive'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={'dip-button'}
                                onClick={() => reInvite(row.id)}
                                sx={{
                                  cursor: 'pointer !important',
                                }}>
                                Re-Invite
                              </Typography>
                            </TableCell>
                            <TableCell className='pointer'>
                              <img
                                src={EditIcon}
                                alt='Edit'
                                onClick={() =>
                                  navigate(
                                    `${AdminPrivateRoutes.SETTINGS.PRODUCTADMINISTRATOR.PRODUCTADMINISTRATOREDIT}/${row.id}`,
                                  )
                                }
                              />
                              <img
                                src={DeleteIcon}
                                alt='Delete'
                                className='ml-24'
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  setConfirmOpen((pre: any) => ({
                                    ...pre,
                                    show: true,
                                    id: row.id,
                                  }))
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <tr className={'text-center'}>
                          <td colSpan={5} align={'center'}>
                            No Record(s) Found.
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* {pagetotal > 0 && (
                  <Typography component={'div'} className={'show-text mt-24'}>
                    Total No. of Records: {pagetotal}
                  </Typography>
                )} */}
              </Typography>
              <Grid container >
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='d-flex-a flex-end pagination'>
                    <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Typography>
        </Grid>
        <ImportFunctionality
          importUpload={importUpload}
          setImportUpload={setImportUpload}
          name={'users'}
          header={[
            'Employee ID',
            'First Name',
            'Last Name',
            'Country',
            'State',
            'City',
            'Pincode',
            'Email',
            'Phone No',
            'Branch',
            'Saturday Leave',
            'Sunday Leave',
            'Status',
          ]}
          getList={getList}
          productAdmin={true}
        />
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? deletebtn(confirmOpen.id)
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default ProductAdministrator;
