import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import PreIcon from 'assets/png/pre.svg';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Paginate from 'components/Pagination/Pagination';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import { useDebounce } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { getAllListData } from 'services/CommonServices';
import moment from 'moment';
import { InterviewFilterDrawer } from '../InterviewFilter/InterviewFilterDrawer';
import Loader from 'components/loader';
import { ResumesENDPOINT } from 'utils/Constant';
import { AccessDenied, currentResumeStage, currentResumeStatus } from 'utils/helper';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import { toast } from 'react-toastify';

const Defaultparam = {
  search: null,
  userId: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 50,
  from: null,
  to: null,
  crm: null,
  organizationId: null,
  currentStatus: null,
  employee: null,
};

export const InterviewList = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);

  const [params, setParams] = useState<Record<string, number | string | null>>(Defaultparam);
  const [dweropen, setdweropen] = useState(false);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [commonState, setcommonstate] = useState<any[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const handleScroll = (scrollAmount: number) => {
    if (!containerRef.current) {
      return;
    }
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  const handleFilterPag = (filteredobj1: any) => {
    setParams((prev) => ({
      ...prev,
      ...filteredobj1,
    }));
  };

  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      // `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      // `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.from ? `interviewFrom=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `interviewTo=${debouncedParams.to}` : null}`,
      `${debouncedParams.employee ? `createdBy=${debouncedParams.employee}` : null}`,
      `${debouncedParams.branch ? `branchId=${debouncedParams.branch}` : null}`,
      `${debouncedParams.minExperience ? `expMin=${debouncedParams.minExperience}` : null}`,
      `${debouncedParams.maxExperience ? `expMax=${debouncedParams.maxExperience}` : null}`,
      `${debouncedParams.currentStatus ? `currentStatus=${debouncedParams.currentStatus}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screen=interview',
      // !debouncedParams.employee ? (roleId !== 6 ? `respectiveUserId=${respectiveUserId}` : '') : '',
      !debouncedParams.currentStatus ? 'resumeStatusType=interview,offer,joining,billing' : '',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoading(true);
    getAllListData(
      `${ResumesENDPOINT.resumeList}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLoading(!true);
      })
      .catch((e) => {
        setLoading(!true);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);

  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.INTERVIEW.INTERVIEWLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Interviews
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>Interviews</Typography>
          <Typography component={'div'} className='expot-icon'>
            <span>
              <ExcelIcon
                url={`${ResumesENDPOINT.resumeList}${
                  Object.values({ ...params }).length > 0 && '?'
                }${[
                  'type=export',
                  'screen=interview',
                  'order=desc',
                  'sortby=createdAt',
                  `${orgdy ? `organizationId=${orgdy}` : null}`,
                  `${
                    debouncedParams.search
                      ? `search=${encodeURIComponent(debouncedParams.search)}`
                      : null
                  }`,
                  `${debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null}`,
                  `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
                  `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
                  `${
                    debouncedParams.interviewFrom
                      ? `interviewFrom=${debouncedParams.interviewFrom}`
                      : null
                  }`,
                  `${
                    debouncedParams.interviewTo
                      ? `interviewTo=${debouncedParams.interviewTo}`
                      : null
                  }`,
                  `${debouncedParams.employee ? `createdBy=${debouncedParams.employee}` : null}`,
                  `${debouncedParams.branch ? `branchId=${debouncedParams.branch}` : null}`,
                  `${
                    debouncedParams.minExperience ? `expMin=${debouncedParams.minExperience}` : null
                  }`,
                  `${
                    debouncedParams.maxExperience ? `expMax=${debouncedParams.maxExperience}` : null
                  }`,
                  `${
                    debouncedParams.currentStatus
                      ? `currentStatus=${debouncedParams.currentStatus}`
                      : null
                  }`,
                  // !debouncedParams.employee
                  //   ? roleId !== 6
                  //     ? `respectiveUserId=${respectiveUserId}`
                  //     : ''
                  //   : '',
                  !debouncedParams.currentStatus
                    ? 'resumeStatusType=interview,offer,joining,billing'
                    : '',
                ]
                  .filter((ea: any) => ea !== 'null')
                  .join('&')}`}
                fileName={'interviews'}
                setLoader={setLoading}
              />
            </span>
            {/* <span style={{ width: '12.17px' }}></span> */}
            {/* <span> */}
            {/* <PdfIcon /> */}
            {/* </span> */}
          </Typography>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Typography component={'div'} className='d-flex-cc flex-sb'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <Typography
                component={'div'}
                className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                onClick={dweropn}>
                <img src={Funnelimg} alt='filter funnel icon' />
                <span className='filterfunnel'>More Filters</span>
              </Typography>
            </Typography>
            <Typography
              component={'div'}
              className='mt-32'
              sx={{ position: 'relative', width: 'calc(100% - 16px)' }}>
              <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                <img src={PreIcon} alt='' />
              </Button>
              <Button onClick={() => handleScroll(200)} className='next-btn next-btn2'>
                <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
              </Button>
              <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                <Table
                  sx={{ overflowX: 'auto', minWidth: 650 }}
                  stickyHeader
                  className='custom-table custom-table-client'
                  aria-label='sticky Header'>
                  <TableHead>
                    <TableRow
                      sx={{
                        marginLeft: '50px',
                        marginRight: '43px',

                        position: 'relative',
                      }}>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Candidate Name
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('name', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('name', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Position
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('jobTitle', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('jobTitle', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Company
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('client', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('client', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        CRM
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('crmUser', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('crmUser', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Recruiter
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdBy', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdBy', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Interview Date
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('interviewDate', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('interviewDate', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Stage
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('stage', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('stage', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Status
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('status', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('status', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        Created On
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commonState?.length ? (
                      commonState.map(
                        ({
                          personal,
                          position,
                          id,
                          client: { corporateName },
                          user,
                          interview,
                          resumeStatus,
                          createdAt,
                        }) => {
                          const candidateName = personal
                            ? `${personal.firstName} ${personal.lastName || ''}`
                            : '-';
                          const positionName = position.title || '-';
                          const company = corporateName || '-';
                          const crm = position?.crm?.length
                            ? position?.crm[0]?.crm?.user
                              ? `${position?.crm[0]?.crm?.user?.firstName} ${
                                  position?.crm[0]?.crm?.user?.lastName || ''
                                }`
                              : '-'
                            : '-';
                          const recruiter = user ? `${user.firstName} ${user.lastName || ''}` : '-';
                          const interviewDate = interview?.length
                            ? moment(interview[0].dateTime).format('DD-MMM-YYYY')
                            : '-';
                          const createdAtDate = createdAt
                            ? moment(createdAt).format('DD-MMM-YYYY')
                            : '-';
                          const interviewLevel = currentResumeStage(resumeStatus) || '-';
                          const status = currentResumeStatus(resumeStatus) || '-';
                          return (
                            <TableRow key={id}>
                              <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                                <Typography
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    color: '#5F2EE5',
                                    fontSize: '14px !important',
                                  }}
                                  onClick={() =>
                                    gotoNavigate(
                                      `${AdminPrivateRoutes.INTERVIEW.INTERVIEWVIEW}?id=${id}`,
                                    )
                                  }>
                                  {candidateName}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{positionName}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{company}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{crm}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{recruiter}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{interviewDate}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{interviewLevel}</TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Typography
                                  component={'button'}
                                  className='interview-status-btn'
                                  sx={{
                                    backgroundColor: currentResumeStatus(resumeStatus, true),
                                    width: '100% !important',
                                  }}>
                                  <Typography component={'span'}>{status}</Typography>
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ whiteSpace: 'nowrap' }}>{createdAtDate}</TableCell>
                            </TableRow>
                          );
                        },
                      )
                    ) : (
                      <tr>
                        <td colSpan={14} align={'center'}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginBottom: '43px',
                            }}>
                            <NCAdedIcon />
                            <Typography className='no-client'>No Interviews added</Typography>
                          </div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              
            </Typography>
            <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
          </Typography>
        </Typography>
      </Grid>
      <InterviewFilterDrawer
        setParams={setParams}
        isOpen={dweropen}
        closeDrawer={dwerclose}
        callback={handleFilterPag}
        setcommonstate={setcommonstate}
        commonState={commonState}
        setLoading={setLoading}
        isLoading={isLoading}
        setpagetotal={setpagetotal}
        listdataapi={listdataapi}
      />
    </Grid>
  );
};
