// import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Modal, Typography } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import React from 'react';

type ModalProps = {
  open?: any;
  close?: any;
  title?: string;
  children?: any;
  onConfirm?: any;
  floatingBtnShow?: any;
  autoCloseDisable?: boolean;
  visibleClose?: boolean;
};

const ConfirmDialog: React.FC<ModalProps> = ({
  open,
  close,
  title,
  children,
  onConfirm,
  floatingBtnShow,
  autoCloseDisable = false,
  visibleClose = false,
}) => {
  return (
    <Modal
      open={open}
      {...(autoCloseDisable ? {} : { onClose: () => close() })}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box className='logout-modal' sx={{ width: '400px !important' }}>
        {visibleClose && (
          <Typography className='text-right'>
            <img
              src={CloseIcon}
              alt='close'
              style={{ cursor: 'pointer' }}
              onClick={() => close()}
            />
          </Typography>
        )}
        <Typography className='f-20 l-title text-center'>{title}</Typography>
        <Typography className='f-15 l-info pm-txt'>{children}</Typography>

        {floatingBtnShow && (
          <Typography component={'div'} className='d-flex-ja mt-1 '>
            <Button className='l-cancel-btn mr-16' onClick={() => onConfirm('no')}>
              {'No'}
            </Button>
            <Button className='l-save-btn' onClick={() => onConfirm('yes')} autoFocus>
              {'Yes'}
            </Button>
          </Typography>
        )}
      </Box>
    </Modal>
  );
};
export default ConfirmDialog;
