import {
  Breadcrumbs,
  Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { To, useNavigate } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { useEffect, useState } from 'react';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import { useDebounce } from 'hooks';
import { SETTING_ENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import { AccessDenied } from 'utils/helper';
import ImportFunctionality from '../Common/import';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  // boxShadow:
  //   theme.palette.mode === 'dark'
  //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto #D0D5DD',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#0061FF',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0061FF',
  },
});

function createData(
  name?: string,
  calories?: number,
  fat?: number,
  carbs?: number,
  protein?: number,
) {
  return { name, calories, fat, carbs, protein };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rows = [
  createData('Frozen yoghurt'),
  createData('Ice cream sandwich'),
  createData('Eclair'),
  createData('Cupcake'),
  createData('Gingerbread'),
];

const Defaultparam = {
  search: null,
  skip: 0,
  order: 'asc',
  sortby: 'name',
  status: null,
  take: 50,
  address: null,
  pincode: null,
  type: null,
};

const Designation = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [importUpload, setImportUpload] = useState<any>(false);
  const [Ld, setLd] = useState(false);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const listdataapi = () => {
    // const searcharr = [`${orgdy ? `id=${orgdy}`:null}`,`${debouncedParams.search ? `search=${debouncedParams.search}`:null}`,`${debouncedParams.status ? `status=${debouncedParams.status}`:null}`,`${debouncedParams.order ? `order=${debouncedParams.order}`:null}`,`${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}`:null}`,`${debouncedParams.skip && debouncedParams.take ? `skip=${debouncedParams.skip?debouncedParams.skip:0}`:debouncedParams.take?`skip=0`:null}`,`${debouncedParams.take ? `take=${debouncedParams.take}`:null}`].filter((ea:any) => ea !== 'null').join('&');
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    getAllListData(
      `${SETTING_ENDPOINT.designation}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(!true);
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);

  const deletebtn = async (id: any) => {
    try {
      setLd(true);
      await deleteData(id, `${SETTING_ENDPOINT.designation}`, true);
      // toast.success(deleted.message, alertOptions);
      setConfirmOpen({
        show: false,
        id: '',
      });
      listdataapi();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLd(!true);
    }
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Designation
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>Designation</Typography>
            <Typography component={'div'} className='d-flex-a'>
              <div className='expot-icon'>
                <span>
                  <ExcelIcon
                    url={'designation?type=export&order=asc&sortby=name'}
                    fileName={'Designation'}
                    setLoader={setLd}
                  />
                </span>
              </div>
              <Button
                className='add-btn'
                onClick={() => setImportUpload(true)}
                style={{ marginRight: '20px' }}>
                Import
              </Button>{' '}
              <Button
                className='add-btn'
                onClick={() =>
                  gotoNavigate(AdminPrivateRoutes.SETTINGS.Designation.DesignationADD)
                }>
                <img src={AddIcon} alt='plus' /> Add Designation
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <Typography component={'div'} className='mt-32'>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Designation Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Designation Type
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('type', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('type', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('status', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('status', 'asc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row && row.name ? row.name : ' '}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row && row.type ? row.type : ' '}
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={row && row.status ? 'active-badge' : 'inactive-badge'}>
                                {row && row.status ? 'Active' : 'Inactive'}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ display: 'flex' }}>
                              <img
                                src={EditIcon}
                                alt='Edit'
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    AdminPrivateRoutes.SETTINGS.Designation.DesignationEDIT,
                                    {
                                      state: row,
                                    },
                                  )
                                }
                              />
                              <img
                                src={DeleteIcon}
                                alt='Delete'
                                className='ml-24'
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  setConfirmOpen((pre: any) => ({
                                    ...pre,
                                    show: true,
                                    id: row.id,
                                  }))
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <tr className={'text-center'}>
                          <td colSpan={5} align={'center'}>
                            No Record(s) Found.
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* {pagetotal > 0 && (
                  <Typography component={'div'} className={'show-text mt-24'}>
                    Total No. of Records: {pagetotal}
                  </Typography>
                )} */}
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>
        <ImportFunctionality
          importUpload={importUpload}
          setImportUpload={setImportUpload}
          name={'designation'}
          header={['Designation Name', 'Designation Type', 'Status']}
          getList={listdataapi}
        />
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? deletebtn(confirmOpen.id)
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default Designation;
