/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  // Pagination,
  // PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { useDebounce } from 'hooks';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
// import { toast } from 'react-toastify';
import Paginate from 'components/Pagination/Pagination';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import moment from 'moment';
import { finalCurrency } from 'utils/Config';
// import { alertOptions } from 'utils/Config';
// import { isNil } from 'lodash';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  sortby?: string | null;
  status?: string | null;
  take?: number | null;
  address?: string | null;
  pincode?: string | null;
  type?: string | null;
}

const Defaultparam = {
  search: null,
  order: '',
  sortby: '',
  skip: 0,
  status: null,
  take: 50,
  address: null,
  pincode: null,
  type: null,
};

const CtcSlab = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [ctcSlabData, setCtcSlabData] = useState<any>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [loader, setLoader] = useState<any>(false);

  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);

  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.CtcSlab}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res) => {
        setpagetotal(Number(res.total));
        setCtcSlabData(res && res.data);
        setLoader(false);
      })
      .catch((e) => {
        // console.log(e);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };
  const deleteCtcSlab = (id: any) => {
    setLoader(true);
    deleteData(id, `${SETTING_ENDPOINT.CtcSlab}`, true)
      .then(() => {
        getList();
        setConfirmOpen((pre: any) => ({
          ...pre,
          show: false,
        }));
        // toast.success('Branch deleted successfully', alertOptions);
        setLoader(false);
      })
      .catch(() => {
        // console.log(err);
        setLoader(false);
      });
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  useEffect(() => {
    getList();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> CTC-Slab
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>CTC-Slab</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={'ctc-slab?type=export&order=&sortby='}
                  fileName={'CTC-Slab'}
                  setLoader={setLoader}
                />
              </span>
            </div>
            <Button className='add-btn' onClick={() => navigate('/settings/ctc-slab/add')}>
              <img src={AddIcon} alt='plus' /> Add CTC-Slab
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {/* <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams(() => ({ ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            /> */}
            <Typography component={'div'}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Min. CTC{' '}
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('name', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('name', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell>
                        Max. CTC{' '}
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('type', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('type', 'desc')}
                          />
                        </span> */}
                      </TableCell>
                      {/* <TableCell>Address</TableCell> */}
                      <TableCell>
                        Target{' '}
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('status', 'desc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('status', 'asc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell>
                        Start Date{' '}
                        {/* <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('status', 'desc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('status', 'asc')}
                          />
                        </span> */}
                      </TableCell>
                      <TableCell>End Date </TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ctcSlabData && ctcSlabData.length ? (
                      ctcSlabData.map((row: any) => {
                        const canEditOrEdit = moment(row.startDate).isSameOrAfter(new Date());
                        return (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row.minCtc
                                ? `${finalCurrency(
                                    row.minCtc,
                                    row.minCurrency,
                                  )} ${row.minCurrency.toUpperCase()}`
                                : '-'}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row.maxCtc
                                ? `${finalCurrency(
                                    row.maxCtc,
                                    row.maxCurrency,
                                  )} ${row.maxCurrency.toUpperCase()}`
                                : '-'}
                            </TableCell>
                            <TableCell>{row.target ? row.target : '-'}</TableCell>
                            <TableCell>
                              {row.startDate ? moment(row.startDate).format('DD-MMM-YYYY') : '-'}
                            </TableCell>{' '}
                            <TableCell>
                              {row.endDate ? moment(row.endDate).format('DD-MMM-YYYY') : '-'}
                            </TableCell>
                            <TableCell>
                              {row.status === true
                                ? 'Active'
                                : row.status === false
                                  ? 'Inactive'
                                  : '-'}
                            </TableCell>
                            <TableCell>
                              <img
                                src={EditIcon}
                                alt='Edit'
                                style={
                                  canEditOrEdit
                                    ? { cursor: 'pointer' }
                                    : { opacity: 0.5, cursor: 'not-allowed' }
                                }
                                onClick={() =>
                                  canEditOrEdit &&
                                  navigate(`${AdminPrivateRoutes.SETTINGS.CTC.CTCEDIT}/${row.id}`)
                                }
                              />
                              <img
                                src={DeleteIcon}
                                style={
                                  canEditOrEdit
                                    ? { cursor: 'pointer' }
                                    : { opacity: 0.5, cursor: 'not-allowed' }
                                }
                                alt='Delete'
                                className='ml-24 pointer'
                                onClick={() =>
                                  canEditOrEdit &&
                                  setConfirmOpen((pre: any) => ({
                                    ...pre,
                                    show: true,
                                    id: row.id,
                                  }))
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={7} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              
            </Typography>
            <Grid container className=''>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <ConfirmDialog
        title='Confirm'
        floatingBtnShow={true}
        open={confirmOpen.show}
        close={() =>
          setConfirmOpen({
            show: false,
          })
        }
        visibleClose={true}
        onConfirm={(act: any) =>
          act === 'yes'
            ? deleteCtcSlab(confirmOpen.id)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};

export default CtcSlab;
